import { Languages } from '@livv/models';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Button, ClickAwayListener, List, ListItem, Popper, SxProps } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import NavBarLink from './NavBarLink';
import { ListItemSx, ListSx } from './navBarSx';
import { LOCAL_STORAGE_LANGUAGE } from '@utils/consts/language';
import { sxs } from '@utils/mui/sx';

interface LanguageMenuProps {
    sx?: SxProps;
}

const LanguageMenu: FC<LanguageMenuProps> = ({ sx = {} }) => {
    const { lang } = useTranslation();
    const { asPath } = useRouter();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const menuId = 'language-menu';
    const triggerId = 'language-button';

    const handleChange = (targetLanguage: Languages) => {
        window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE, targetLanguage);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <Button
                    aria-controls={menuId}
                    aria-expanded={anchorEl ? 'true' : undefined}
                    aria-haspopup="true"
                    endIcon={<KeyboardArrowDownOutlined />}
                    id={menuId}
                    onClick={handleClick}
                    sx={sxs([
                        {
                            '&:hover': {
                                backgroundColor: 'grey.300',
                            },
                            color: 'common.white',
                            fontSize: { tablet: 'body1.fontSize', xs: 'body2.fontSize' },
                        },
                        sx,
                    ])}
                >
                    {lang.toUpperCase()}
                </Button>
                <Popper
                    anchorEl={anchorEl}
                    open={null !== anchorEl}
                    sx={{ zIndex: 1 }}
                    disablePortal
                >
                    <List aria-labelledby={triggerId} id={menuId} sx={ListSx} dense disablePadding>
                        {Object.values(Languages).map((language) => (
                            <ListItem
                                key={language}
                                onClick={() => {
                                    handleChange(language);
                                    handleClose();
                                }}
                                role="none"
                                sx={ListItemSx}
                            >
                                <NavBarLink
                                    data-test-id="language-nav-link"
                                    href={asPath}
                                    role="menuitem"
                                    title={language}
                                    isFreeAccess
                                >
                                    {language.toUpperCase()}
                                </NavBarLink>
                            </ListItem>
                        ))}
                    </List>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default LanguageMenu;
