import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, RefObject, useMemo } from 'react';
import { navBarItemSx } from './navBarSx';
import { sxs } from '@utils/mui/sx';
import { NavigationLink } from '@utils/navigation';

interface ButtonNestedMenuProps {
    id: string;
    isOpen: boolean;
    links: NavigationLink[];
    menuId?: string;
    menuTitle: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    ref?: RefObject<HTMLButtonElement>;
}

const ButtonNestedMenu: FC<ButtonNestedMenuProps> = ({
    id,
    isOpen,
    links,
    menuId,
    menuTitle,
    onClick,
    ref,
}) => {
    const { t } = useTranslation('navBar');
    const router = useRouter();

    const isActive = useMemo(
        () => links.map(({ href }) => href).includes(router.pathname),
        [links, router.pathname],
    );

    return (
        <Button
            ref={ref}
            aria-controls={menuId}
            aria-expanded={isOpen ? 'true' : undefined}
            aria-haspopup="true"
            data-test-id={`${menuTitle}-button-nested-menu`}
            id={id}
            onClick={onClick}
            size="small"
            sx={sxs([
                navBarItemSx,
                {
                    color: isActive ? 'white' : 'grey.400',
                    cursor: 'pointer',
                    display: 'flex',
                    fontWeight: 'medium',
                    p: 0,
                },
            ])}
        >
            {t(`link.${menuTitle}`)}
            <ArrowDropDownIcon
                sx={[
                    { mt: '-1px', transition: 'transform 0.5s' },
                    isOpen && { transform: 'rotateZ(-180deg)' },
                ]}
            />
        </Button>
    );
};

export default ButtonNestedMenu;
