import { ClickAwayListener, List, ListItem, Popper } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import ButtonNestedMenu from '../ButtonNestedMenu';
import NavBarLink from '../NavBarLink';
import { ListItemSx, ListSx } from '../navBarSx';
import { NavigationMenu } from '@utils/navigation';

interface DesktopNestedMenuProps {
    navigationMenu: NavigationMenu;
}

const DesktopNestedMenu: FC<DesktopNestedMenuProps> = ({
    navigationMenu: { links, title: menuTitle },
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation('navBar');

    const menuId = `${menuTitle}-menu`;
    const triggerId = `${menuTitle}-button`;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                <ButtonNestedMenu
                    id={triggerId}
                    isOpen={Boolean(anchorEl)}
                    links={links}
                    menuId={menuId}
                    menuTitle={menuTitle ?? ''}
                    onClick={handleClick}
                />
                <Popper
                    anchorEl={anchorEl}
                    open={null !== anchorEl}
                    sx={{ zIndex: 1 }}
                    disablePortal
                    keepMounted
                >
                    <List aria-labelledby={triggerId} id={menuId} sx={ListSx} dense disablePadding>
                        {links.map(({ isExternalLink, isFreeAccess, href, title }) => (
                            <ListItem key={`${title}_${href}`} role="none" sx={ListItemSx}>
                                <NavBarLink
                                    data-test-id={`${title}-nav-link`}
                                    href={href}
                                    isFreeAccess={isFreeAccess}
                                    onClick={handleClose}
                                    role="menuitem"
                                    title={title}
                                    {...(isExternalLink && { target: '_blank' })}
                                >
                                    {t(`link.${title}`)}
                                </NavBarLink>
                            </ListItem>
                        ))}
                    </List>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default DesktopNestedMenu;
